import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Publication } from "./publication";

@Injectable({
  providedIn: "root",
})
export class PublicationService {
  private apiUrl = `${environment.baseUrl}api/publication/`;
  constructor(private http: HttpClient) {}

  getAllPublications(): Promise<Publication[]> {
    return this.http.get<Publication[]>(`${this.apiUrl}get-all-with-delete`).toPromise();
  }

  getPublication(id: number): Promise<Publication> {
    return this.http.get<Publication>(`${this.apiUrl}get/${id}`).toPromise();
  }

  getAdminPublication(id: number): Promise<Publication> {
    return this.http.get<Publication>(`${this.apiUrl}admin/get/${id}`).toPromise();
  }

  getPreviewPublication(id: number): Promise<Publication> {
    return this.http.get<Publication>(`${this.apiUrl}get/preview/${id}`).toPromise();
  }

  addNewPublication(publication: Publication) {
    return this.http.post(`${this.apiUrl}save`, publication);
  }

  updatePublication(publication: Publication) {
    return this.http.post(`${this.apiUrl}update`, publication);
  }

  saveImage(formData: FormData) : Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/image/upload`, formData);
  }
}
