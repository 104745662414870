import {
  faSignInAlt,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { User } from "src/app/shared/models/user";
import { AccountService } from "src/app/shared/Services/account.service";
import { Router } from "@angular/router";

@Component({
  selector: "nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  showMobileMenu = false;
  faSignIcon = faSignInAlt;
  user: User;
  @ViewChild("insideElement", { static: false }) insideElement;
  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement) {
    const clickedInside =
      this.insideElement.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showMobileMenu = false;
    }
  }

  constructor(
    private accountService: AccountService,
    private router: Router,
    private eRef: ElementRef
  ) {}
  ngOnInit() {
    this.accountService.user.subscribe((result) => {
      this.user = result;
      if (this.user) this.faSignIcon = faSignOutAlt;
      console.log(result);
    });
  }

  logout() {
    this.accountService.logout().subscribe();
    this.router.navigate(["/"]);
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
