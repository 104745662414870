import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "fire",
  templateUrl: "./fire.component.html",
  styleUrls: ["./fire.component.scss"],
})
export class FireComponent implements OnInit {
  @ViewChild("videoPlayer",null) videoplayer: ElementRef;

 
  constructor() {}

  ngOnInit() {
    this.toggleVideo(null);
  }
  toggleVideo(event: any) {
    this.videoplayer.nativeElement.muted = true;
    this.videoplayer.nativeElement.play();
  }
}
