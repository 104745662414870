import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import localeRu from "@angular/common/locales/ru";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./site/components/nav-menu/nav-menu.component";
import { SiteComponent } from "./site/site.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { AppRoutingModule } from "./app.routing";
import { HomeComponent } from "./site/pages/home/home.component";
import { FooterComponent } from "./site/components/footer/footer.component";
import { EventComponent } from "./site/pages/event/event.component";
import { NewsComponent } from "./site/pages/news/news.component";
import { FireComponent } from "./site/components/fire/fire.component";
import { PublicationsVerticalComponent } from "./site/components/publications-vertical/publications-vertical.component";
import { NewsService } from "./site/pages/news/news.service";
import { PublicationsHorizontalComponent } from "./site/components/publications-horizontal/publications-horizontal.component";
import { PublicationsSidebarComponent } from "./site/components/publications-sidebar/publications-sidebar.component";
import { TruncatePipe } from "./shared/pipes/TruncatePipe.pipe";
import { registerLocaleData } from "@angular/common";
import { SortByPipe } from "./shared/pipes/sortBy.pipe";
import { SideButtonsComponent } from "./site/components/side-buttons/side-buttons.component";
import { CommonService } from "./shared/Services/common.service";
import { PaginationComponent } from "./site/components/pagination/pagination.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NavMenuDirective } from "./site/components/nav-menu/nav-menu.directive";
import { PublicationsComponent } from "./site/pages/publications/publications.component";
import { NewsItemComponent } from "./site/pages/news-item/news-item.component";
import { PersonalAreaComponent } from "./site/components/personal-area/personal-area.component";
import { PersonalAreaService } from "./site/components/personal-area/personal-area.service";
import { ShowLkDirective } from "./shared/directives/show-lk.directive";
import { ErrorPageComponent } from "./site/pages/error/error.component";
import { ProfileComponent } from "./site/pages/profile/profile.component";
import { HomeSliderComponent } from "./site/components/home-slider/home-slider.component";
import { SwiperModule } from "swiper/angular";
import { ProfileMainComponent } from "./site/pages/profile/profile-main/profile-main.component";
import { ProfileEmailComponent } from "./site/pages/profile/profile-email/profile-email.component";
import { ModalService } from "./shared/components/modal/modal.service";
import { ModalComponent } from "./shared/components/modal/modal.component";
import { ChestsComponent } from "./site/pages/chests/chests.component";
import { newPGComponent } from "./site/pages/newPG/newPG.component";
import { aboutComponent } from "./site/pages/about/about.component";
import { ShareButtonsComponent } from "./site/components/share-buttons/share-buttons.component";
import { ModalXlComponent } from "./site/components/modal-xl/modal-xl.component";
import { AngularCountdownTimerModule } from "angular8-countdown-timer";
import { CountdownComponent } from "./site/components/Countdown/Countdown.component";

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SiteComponent,
    FooterComponent,
    HomeComponent,
    EventComponent,
    NewsComponent,
    NewsItemComponent,
    ProfileComponent,
    ProfileMainComponent,
    ProfileEmailComponent,
    ChestsComponent,
    newPGComponent,
    FireComponent,
    PublicationsVerticalComponent,
    PublicationsHorizontalComponent,
    PublicationsSidebarComponent,
    ModalXlComponent,
    SideButtonsComponent,
    ShareButtonsComponent,
    PersonalAreaComponent,
    TruncatePipe,
    SortByPipe,
    PaginationComponent,
    NavMenuDirective,
    PublicationsComponent,
    ShowLkDirective,
    ErrorPageComponent,
    HomeSliderComponent,
    ModalComponent,
    CountdownComponent,
    aboutComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    NzLayoutModule,
    NzTableModule,
    NzRadioModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SwiperModule,
    AngularCountdownTimerModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: LOCALE_ID, useValue: "ru" },
    NewsService,
    CommonService,
    PersonalAreaService,
    ModalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
