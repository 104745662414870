import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { ProfileService } from "../profile.service";

@Component({
  selector: "profile-email",
  templateUrl: "./profile-email.component.html",
  styleUrls: ["./profile-email.component.scss"],
})
export class ProfileEmailComponent implements OnInit {
  changeEmailForm: FormGroup;
  loading = false;
  submitted = false;
  modalTitleText: string;
  modalBodyText: string;
  faTimes = faTimes;
  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.initChangeEmailForm();
  }
  get f() {
    return this.changeEmailForm.controls;
  }
  onChangeEmail() {
    this.submitted = true;

    if (this.changeEmailForm.invalid) {
      return;
    }

    this.loading = true;
    this.profileService
      .changeEmail(this.f.password.value, this.f.newEmail.value)
      .subscribe(
        () => {
          this.modalTitleText = "Почта изменена успешно.";
          this.modalBodyText = "На почту отправлено письмо с инструкцией";
          this.openModal("change-email");
        },
        () => {
          this.modalTitleText = "При смене почты произошла ошибка.";
          this.modalBodyText = "Вы ввели не правильный текущий пароль";
          this.openModal("change-email");
        }
      );
  }
  initChangeEmailForm() {
    this.changeEmailForm = this.formBuilder.group({
      password: [null, Validators.required],
      newEmail: [null, [Validators.required, Validators.email]],
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.loading = false;
    this.submitted = false;
    this.initChangeEmailForm();
  }
}
