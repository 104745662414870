import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { environment } from "src/environments/environment";

@Component({
  selector: "modal-xl",
  templateUrl: "./modal-xl.component.html",
  styleUrls: ["./modal-xl.component.scss"],
})
export class ModalXlComponent implements OnInit {
  constructor(private modalService: ModalService) {}
  private apiUrl = `${environment.baseUrl}api/`;
  faTimes = faTimes;
  progressbar = 0;
  ngOnInit() {}
  closeModal(id: string) {
    this.modalService.close(id);
  }
  updateBar() {
    if (this.progressbar < 99) {
      this.progressbar += 33.3;
    }
  }
}
