import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "src/app/shared/models/user";
import { AccountService } from "src/app/shared/Services/account.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  currentUser: User;
  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {
    this.currentUser = this.accountService.userValue;
  }

  changePassword(oldPassword: string, newPassword: string) {
    let login = this.currentUser.login;
    return this.http.post(`${environment.baseUrl}api/user/change-password`, {
      login,
      oldPassword,
      newPassword,
    });
  }

  changeEmail(currentPassword: string, newEmail: string) {
    let login = this.currentUser.login;
    return this.http.post(`${environment.baseUrl}api/user/change-email`, {
      login,
      currentPassword,
      newEmail,
    });
  }
}
