export class Publication {
    public id: number;
    public category: number;
    public categoryStr: string;
    public title: string;
    public body: string;
    public showDate: Date;
    public imgUrl: string;
    public isTemporary: boolean;
    public isDelete: boolean;
    public author: string;
}
