import { Component, OnInit } from '@angular/core';
import { Publication } from 'src/app/admin/publication/publication';
import { CommonService } from 'src/app/shared/Services/common.service';
import { environment } from 'src/environments/environment';
import { NewsService } from '../news/news.service';

@Component({
  selector: 'publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {
  private apiUrl = `${environment.baseUrl}api/`;
  allPublications: Publication[] = [];
  currentPublications: Publication[] = [];
  viewBlock: number = 3;
  constructor(
    private newsService: NewsService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.newsService.getAllNews().then((result) => {
      this.allPublications = result;
      this.allPublications.sort((a: Publication, b: Publication) => {
        return new Date(b.showDate).getTime() - new Date(a.showDate).getTime();
      });
      this.allPublications.forEach((element) => {
        element.imgUrl = this.apiUrl + element.imgUrl;
      });
      this.currentPublications = this.allPublications.slice(0, this.viewBlock);
    });
  }

  changePage(started: number) {
    this.currentPublications = this.allPublications.slice(
      started,
      started + this.viewBlock
    );
    this.commonService.scrollToElement("nav-menu");
  }
}
