import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";

@Component({
  selector: "pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnChanges {
  @Input() totalLength: number;
  @Input() viewBlock: number = 3;
  @Output() startedArrayEvent = new EventEmitter<number>();
  totalPage: number;
  arrayPage: number[] = [];
  currentPage: number = 1;
  constructor() {}

  ngOnChanges() {
    this.totalPage = Math.ceil(this.totalLength / this.viewBlock);
    this.arrayPage = Array.from({ length: this.totalPage }, (v, k) => k + 1);
  }

  changePage(newPage: number) {
    this.currentPage = newPage;
    this.startedArrayEvent.emit(newPage * this.viewBlock - this.viewBlock);
  }
}
