import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/internal/operators/filter";
import { PersonalAreaService } from "./components/personal-area/personal-area.service";

@Component({
  selector: "app-site",
  templateUrl: "./site.component.html",
  styleUrls: ["./site.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SiteComponent implements OnInit {
  constructor(
    private router: Router,
    private personalAreaService: PersonalAreaService
  ) {}
  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.personalAreaService.openLk.next(false);
      });
  }
}
