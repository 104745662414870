import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HostListener } from "@angular/core";
import { CommonService } from "src/app/shared/Services/common.service";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { ModalService } from "src/app/shared/components/modal/modal.service";

@Component({
  selector: "side-buttons",
  templateUrl: "./side-buttons.component.html",
  styleUrls: ["./side-buttons.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SideButtonsComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private modalService: ModalService
  ) {}

  ngOnInit() {}

  faChevronUp = faChevronUp;

  scrollToElement(selector: string) {
    this.commonService.scrollToElement(selector);
  }

  //показываем кнопку скролла наверх после 400px
  offsetFlag = false;
  @HostListener("window:scroll", ["$event"]) getScrollHeight(event) {
    if (window.pageYOffset > 400) this.offsetFlag = true;
    else this.offsetFlag = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
}
