import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Publication } from "src/app/admin/publication/publication";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NewsService {
  private apiUrl = `${environment.baseUrl}api/publication/`;
  constructor(private http: HttpClient) {}
  getAllNews(category?: number): Promise<Publication[]> {
    if (category != null) {
      return this.http
        .get<Publication[]>(`${this.apiUrl}get-all?category=${category}`)
        .toPromise();
    } else {
      return this.http.get<Publication[]>(`${this.apiUrl}get-all`).toPromise();
    }
  }
}
