import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor() {}

  scrollToElement(selector: string): void {
    let target = document.querySelector(selector);
    console.log(target);

    target.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
