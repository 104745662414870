import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Publication } from "src/app/admin/publication/publication";
import Parallax from "parallax-js";
import { faLock } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "publications-horizontal",
  templateUrl: "./publications-horizontal.component.html",
  styleUrls: ["./publications-horizontal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PublicationsHorizontalComponent implements OnInit {
  @Input() news: Publication;
  isHover: boolean;
  dateToday = new Date(Date.now());
  newsDate: Date;
  isLocked = false;
  faLock = faLock;

  constructor() {}

  ngOnInit() {
    let scenes = document.querySelector(".nk-blog-list");
    let parallaxInstance = new Parallax(scenes, {
      pointerEvents: true,
      selector: "publications-horizontal",
      scalarY: 4,
      scalarX: 6,
    });
    this.newsDate = new Date(this.news.showDate);

    if (this.newsDate > this.dateToday) {
      this.isLocked = true;
    } else this.isLocked = false;
  }
}
