import { Component, ViewEncapsulation, ViewChild } from "@angular/core";
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination,Autoplay]);

@Component({
  selector: "home-slider",
  templateUrl: "./home-slider.component.html",
  styleUrls: ["./home-slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeSliderComponent {}
