import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  activeTab: string;

  tabs: Array<Tabs> = [
    {
      title: "Главная",
    },
    {
      title: "Email",
    },
  ];

  constructor() {}

  ngOnInit() {
    this.activeTab = this.tabs[0].title;
  }
}

class Tabs {
  title: string;
}
