import { Component, OnInit } from "@angular/core";

@Component({
  selector: "Countdown",
  templateUrl: "./Countdown.component.html",
  styleUrls: ["./Countdown.component.scss"],
})
export class CountdownComponent implements OnInit {
  constructor() {}

  today = new Date(Date.now());
  warDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    21,
    0o0,
    0o0
  );

  dangeDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    20,
    0o0,
    0o0
  );

  targetDate: Date;

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  ngOnInit() {
    if (this.today.getDay() == 6) {
      this.dangeDate = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate() + 1,
        20,
        0o0,
        0o0
      );
    }
    switch (this.today.getDay()) {
      case 1:
        if (this.today < this.warDate) {
          this.targetDate = this.warDate;
        } else {
          this.targetDate = this.addDays(this.dangeDate, 1);
        }
        break;
      case 2:
        if (this.today < this.dangeDate) {
          this.targetDate = this.dangeDate;
        } else {
          this.targetDate = this.addDays(this.warDate, 1);
        }
        break;
      case 3:
        if (this.today < this.warDate) {
          this.targetDate = this.warDate;
        } else {
          this.targetDate = this.addDays(this.dangeDate, 1);
        }
        break;
      case 4:
        if (this.today < this.dangeDate) {
          this.targetDate = this.dangeDate;
        } else {
          this.targetDate = this.addDays(this.warDate, 1);
        }
        break;
      case 5:
        if (this.today < this.warDate) {
          this.targetDate = this.warDate;
        } else {
          this.targetDate = this.addDays(this.dangeDate, 1);
        }
        break;
      case 6:
        if (this.today < this.dangeDate) {
          this.targetDate = this.dangeDate;
        } else {
          this.targetDate = this.addDays(this.warDate, 1);
        }
        break;
      case 7:
        if (this.today < this.dangeDate) {
          this.targetDate = this.dangeDate;
        } else {
          this.targetDate = this.addDays(this.warDate, 1);
        }
        break;
      default:
        break;
    }
  }

  myTriggerFunction() {
    console.log("triggered!");
  }
}
