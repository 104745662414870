import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'chests',
  templateUrl: './chests.component.html',
  styleUrls: ['./chests.component.scss']
})
export class ChestsComponent implements OnInit {
  html: string;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    var result = this.http.get<any>(`${environment.baseUrl}api/get-chest-page`).toPromise();
    result.then(suc => this.html = suc.html);
  }

}
