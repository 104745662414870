import { ThrowStmt } from "@angular/compiler";
import { Directive, HostListener, Renderer2, ElementRef } from "@angular/core";
import { PersonalAreaService } from "src/app/site/components/personal-area/personal-area.service";

@Directive({
  selector: "[showLk]",
})
export class ShowLkDirective {
  currentValue: boolean;
  constructor(
    private personalAreaService: PersonalAreaService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  @HostListener("click") onMouseClick() {
    this.personalAreaService.openLk.subscribe((x) => {
      this.currentValue = x;
      if (this.currentValue) {
        this.renderer.addClass(document.body, "modal-open");
        this.renderer.addClass(this.el.nativeElement, "active");
      } else {
        this.renderer.removeClass(document.body, "modal-open");
        this.renderer.removeClass(this.el.nativeElement, "active");
      }
    });
    this.personalAreaService.openLk.next(!this.currentValue);
  }
}
