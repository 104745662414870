import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
export class EventComponent implements OnInit {
  html: string;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    var result = this.http.get<any>(`${environment.baseUrl}api/get-event-page`).toPromise();
    result.then(suc => this.html = suc.html);
  }
}
