import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Publication } from "src/app/admin/publication/publication";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { environment } from "src/environments/environment";
import { PersonalAreaService } from "../../components/personal-area/personal-area.service";
import { NewsService } from "../news/news.service";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  private apiUrl = `${environment.baseUrl}api/`;
  news: Publication[] = [];
  constructor(
    private newsService: NewsService,
    private router: Router,
    private personalAreaService: PersonalAreaService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.router.url == "/signin" || this.router.url == "/registry")
      this.personalAreaService.openLk.next(true);
    this.newsService.getAllNews().then((result) => {
      this.news = result.filter(x => x.category != 2);
      this.news.forEach((element) => {
        element.imgUrl = this.apiUrl + element.imgUrl;
      });
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
}
