import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Publication } from "src/app/admin/publication/publication";
import { CommonService } from "src/app/shared/Services/common.service";
import { environment } from "src/environments/environment";
import { NewsService } from "./news.service";

@Component({
  selector: "news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewsComponent implements OnInit {
  private apiUrl = `${environment.baseUrl}api/`;
  allPublications: Publication[] = [];
  currentPublications: Publication[] = [];
  viewBlock: number = 3;
  constructor(
    private newsService: NewsService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    var category = 0;
    switch (this.router.url) {
      case "/publications/news":
        category = 0;
        break;
      case "/publications/updates":
        category = 1;
        break;
      case "/publications/weapons":
        category = 2;
        break;
      default:
        break;
    }
    this.newsService.getAllNews(category).then((result) => {
      this.allPublications = result;
      this.allPublications.sort((a: Publication, b: Publication) => {
        return new Date(b.showDate).getTime() - new Date(a.showDate).getTime();
      });
      this.allPublications.forEach((element) => {
        element.imgUrl = this.apiUrl + element.imgUrl;
      });
      this.currentPublications = this.allPublications.slice(0, this.viewBlock);
    });
  }

  changePage(started: number) {
    this.currentPublications = this.allPublications.slice(
      started,
      started + this.viewBlock
    );
    this.commonService.scrollToElement("nav-menu");
  }
}
