import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EventComponent } from "./site/pages/event/event.component";
import { HomeComponent } from "./site/pages/home/home.component";
import { NewsComponent } from "./site/pages/news/news.component";
import { SiteComponent } from "./site/site.component";
import { PublicationsComponent } from "./site/pages/publications/publications.component";
import { NewsItemComponent } from "./site/pages/news-item/news-item.component";
import { ErrorPageComponent } from "./site/pages/error/error.component";
import { ProfileComponent } from "./site/pages/profile/profile.component";
import { ChestsComponent } from "./site/pages/chests/chests.component";
import { newPGComponent} from './site/pages/newPG/newPG.component';
import { aboutComponent } from "./site/pages/about/about.component";

const routes: Routes = [
  {
    path: "",
    component: SiteComponent,
    children: [
      { path: "", component: HomeComponent, pathMatch: "full" },
      { path: "publications", component: PublicationsComponent },
      { path: "publications/news", component: NewsComponent },
      { path: "publications/updates", component: NewsComponent },
      { path: "publications/weapons", component: NewsComponent },
      { path: "publications/news/:id", component: NewsItemComponent },
      { path: "publications/news/preview/:id", component : NewsItemComponent },
      { path: "publications/updates/:id", component: NewsItemComponent },
      { path: "publications/updates/preview/:id", component : NewsItemComponent },
      { path: "publications/weapons/:id", component: NewsItemComponent },
      { path: "publications/weapons/preview/:id", component : NewsItemComponent },
      { path: "signin", component: HomeComponent },
      { path: "registry", component: HomeComponent },
      { path: "profile", component: ProfileComponent },
      { path: "event", component: EventComponent },
      { path: "chests", component: ChestsComponent },
      { path: "error", component: ErrorPageComponent },
      { path: "newPG", component: newPGComponent},
      { path: "about", component: aboutComponent}
    ],
  },
  { path: "admin", loadChildren: "./admin/admin.module#AdminModule" },
  { path: "**", redirectTo: "/error" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
