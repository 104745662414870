import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user"))
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(code, login, password) {
    return this.http
      .post<User>(`${environment.baseUrl}api/user/login`, {
        code,
        login,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null

    return this.http.post(`${environment.baseUrl}api/user/logout`, null).pipe(
      map((user) => {
        localStorage.removeItem("user");
        this.userSubject.next(null);
      })
    );
  }

  register(code, login, password, email) {
    return this.http.post(`${environment.baseUrl}api/user/register`, {
      code,
      login,
      password,
      email,
    });
  }
  
  recovery(code, login, password, email) {
    return this.http.post(`${environment.baseUrl}api/user/recovery-account`, {
      code,
      login,
      password,
      email,
    });
  }
}
