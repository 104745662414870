import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { ProfileService } from "../profile.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "profile-main",
  templateUrl: "./profile-main.component.html",
  styleUrls: ["./profile-main.component.scss"],
})
export class ProfileMainComponent implements OnInit {
  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  modalTitleText: string;
  modalBodyText: string;
  faTimes = faTimes;
  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.initChangePasswordForm();
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  get fr_pas() {
    return this.changePasswordForm.controls.passwords["controls"];
  }
  onChangePassword() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.profileService
      .changePassword(this.f.oldPassword.value, this.fr_pas.password.value)
      .subscribe(
        (data) => {
          this.modalTitleText = "Пароль изменен успешно.";
          this.modalBodyText = "На почту отправлено письмо с инструкцией";
          this.openModal("change-password");
        },
        (error) => {
          this.modalTitleText = "При смене пароля произошла ошибка.";
          this.modalBodyText = "Вы ввели не правильный текущий пароль";
          this.openModal("change-password");
        }
      );
  }
  initChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: [null, Validators.required],
      passwords: this.formBuilder.group(
        {
          password: [null, [Validators.required]],
          confirm_password: [null, [Validators.required]],
        },
        { validator: this.passwordConfirming }
      ),
    });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get("password").value !== c.get("confirm_password").value) {
      return { invalid: true };
    }
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.loading = false;
    this.submitted = false;
    this.initChangePasswordForm();
  }
}
