import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonalAreaService {
public openLk = new ReplaySubject<boolean>(0);
constructor() { }

}
