import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { AccountService } from "src/app/shared/Services/account.service";
import { PersonalAreaService } from "./personal-area.service";
import { environment } from "src/environments/environment";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "personal-area",
  templateUrl: "./personal-area.component.html",
  styleUrls: ["./personal-area.component.scss"],
})
export class PersonalAreaComponent implements OnInit {
  form: FormGroup;
  registerForm: FormGroup;
  recoveryAccountForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  showLk: boolean;
  showForm: string = "signIn";
  msgError: string;
  faTimes = faTimes;
  baseUrl: string = environment.baseUrl;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public personalAreaService: PersonalAreaService,
    private accountService: AccountService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.router.url == "/registry") this.showRegister();
    this.initLoginForm();
    this.personalAreaService.openLk.subscribe((result) => {
      this.showLk = result;
    });

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  get f() {
    return this.form.controls;
  }
  get fr() {
    return this.registerForm.controls;
  }
  get fr_pas() {
    return this.registerForm.controls.passwords["controls"];
  }
  get fr_rec() {
    return this.recoveryAccountForm.controls;
  }

  showLogIn() {
    this.showForm = "signIn";
  }

  showRegister() {
    this.showForm = "registry";
    this.initRegistryForm();
  }

  recoveryAccount() {
    this.showForm = "recoveryAccount";
    this.initRecoveryForm();
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.accountService
      .login(this.f.captcha.value, this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.initLoginForm();
          this.personalAreaService.openLk.next(false);
          this.loading = false;
          this.submitted = false;
          this.initLoginForm();
        },
        (error) => {
          this.loading = false;
          if (error.error.status == 500) {
            this.msgError = "Не верный код с картинки";
          } else {
            switch (error.error.value) {
              case 1:
                this.msgError = "Некорректный логин";
                break;
              case 2:
                this.msgError = "Некорректный пароль";
                break;
              case 3:
                this.msgError = "Произошла ошибка";
                break;
            }
          }
          this.openModal("error-login");
        }
      );
  }

  onRegister() {
    this.submitted = true;
    if (this.registerForm.invalid) return;

    this.loading = true;
    this.accountService
      .register(
        this.fr.captcha.value,
        this.fr.username.value,
        this.fr_pas.password.value,
        this.fr.email.value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.personalAreaService.openLk.next(false);
          this.loading = false;
          this.showForm = "signIn";
          this.submitted = false;
          this.initRegistryForm();
        },
        (error) => {
          this.loading = false;
          if (error.error.status == 500) {
            this.msgError = "Не верный код с картинки";
          } else {
            switch (error.error.value) {
              case 1:
                this.msgError = "Логин уже существует";
                break;
              case 2:
                this.msgError =
                  "Произошла ошбика во время создания аккаунта, попробуйте позже";
                break;
              case 3:
                this.msgError = "Некорректная почта";
                break;
              case 4:
                this.msgError = "Некорректный пароль";
                break;
              case 5:
                this.msgError =
                  "Некорректный логин. Логин должен состоять не менее чем из 6 символов и не более чем из 15 символов. Можно использовать латинские буквы, цифры";
                break;
              case 6:
                this.msgError = "Профиль с такой почтой уже зарегистрирован";
                break;
              default:
                break;
            }
          }
          this.openModal("error-reg");
        }
      );
  }

  onRecoveryAccount() {
    this.submitted = true;
    if (this.recoveryAccountForm.invalid) {
      return;
    }

    this.loading = true;
    this.accountService
      .recovery(
        this.fr_rec.captcha.value,
        this.fr_rec.username.value,
        this.fr_rec.password.value,
        this.fr_rec.email.value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.openModal("recovery-modal");
          this.loading = false;
          this.submitted = false;
          this.initRecoveryForm();
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  closeRecoveryModal() {
    this.closeModal("recovery-modal");
    this.personalAreaService.openLk.next(false);
  }

  initLoginForm() {
    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
      captcha: ["", Validators.required],
    });
  }

  initRegistryForm() {
    this.registerForm = this.formBuilder.group({
      username: [
        null,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15),
          Validators.pattern("[a-zA-Z0-9 ]*"),
        ],
      ],
      passwords: this.formBuilder.group(
        {
          password: [
            null,
            [
              Validators.required,
              Validators.minLength(6),
              Validators.maxLength(15),
            ],
          ],
          confirm_password: [null, [Validators.required]],
        },
        { validator: this.passwordConfirming }
      ),
      email: ["", [Validators.required, Validators.email]],
      captcha: ["", Validators.required],
    });
  }

  initRecoveryForm() {
    this.recoveryAccountForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      captcha: [null, Validators.required],
    });
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get("password").value !== c.get("confirm_password").value) {
      return { invalid: true };
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
