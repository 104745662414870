import { Component, Input, OnInit } from "@angular/core";
import { Publication } from "src/app/admin/publication/publication";
import { faLock } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "publications-vertical",
  templateUrl: "./publications-vertical.component.html",
  styleUrls: ["./publications-vertical.component.scss"],
})
export class PublicationsVerticalComponent implements OnInit {
  @Input() news: Publication;
  dateToday = new Date(Date.now());
  newsDate: Date;
  isLocked = false;
  faLock = faLock;
  url: string;
  ngOnInit() {
    this.newsDate = new Date(this.news.showDate);

    if (this.newsDate > this.dateToday) {
      this.isLocked = true;
    } else this.isLocked = false;

    if (this.news) {
      switch (this.news.category) {
        case 0:
          this.url = "/publications/news/" + this.news.id;
          break;
        case 1:
          this.url = "/publications/updates/" + this.news.id;
          break;
        case 2:
          this.url = "/publications/weapons/" + this.news.id;
          break;
        default:
          break;
      }
    }
  }
}
