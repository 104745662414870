import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Publication } from "src/app/admin/publication/publication";
import { PublicationService } from "src/app/admin/publication/publication.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "news-item",
  templateUrl: "./news-item.component.html",
  styleUrls: ["./news-item.component.scss"],
})
export class NewsItemComponent implements OnInit, OnDestroy {
  private apiUrl = `${environment.baseUrl}api/`;
  publication: Publication = new Publication();
  currentId: number;
  subscription: Subscription;
  dateToday = new Date(Date.now());
  newsDate: Date;
  isLocked = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private publicationService: PublicationService
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe((params) => {
      this.currentId = params["id"]; //log the value of id
    });
    if (this.router.url.indexOf("preview") > -1) {
      this.initPreview();
    } else {
      this.initNews();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initNews() {
    this.publicationService.getPublication(this.currentId).then((result) => {
      if (result == null) this.router.navigate(["error"]);

      this.newsDate = new Date(result.showDate);
      this.publication = result;
      this.publication.imgUrl = this.apiUrl + this.publication.imgUrl;
      if (this.newsDate > this.dateToday) {
        this.router.navigate(["error"]);
        return
      }
      if (this.publication.category == 1) {
        this.router.navigate([
          "publications/updates/" + this.publication.id,
        ]);
      } else if (this.publication.category == 0) {
        this.router.navigate([
          "publications/news/" + this.publication.id,
        ]);
      }
      else if (this.publication.category == 0) {
        this.router.navigate([
          "publications/weapons/" + this.publication.id,
        ]);
      }
    });
  }

  initPreview() {
    this.publicationService
      .getPreviewPublication(this.currentId)
      .then((result) => {
        this.publication = result;
        this.publication.imgUrl = this.apiUrl + this.publication.imgUrl;
        if (this.publication.category == 1) {
          this.router.navigate([
            "publications/updates/preview/" + this.publication.id,
          ]);
        } else if (this.publication.category == 0) {
          this.router.navigate([
            "publications/news/preview/" + this.publication.id,
          ]);
        } else if (this.publication.category == 2) {
          this.router.navigate([
            "publications/weapons/preview/" + this.publication.id,
          ]);
        }
        
      });
  }
}
