import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appNavMenu]",
})
export class NavMenuDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("mouseover") onMouseOver() {
    this.renderer.addClass(this.el.nativeElement, "open");
    this.renderer.setStyle(
      this.el.nativeElement.children[1],
      "display",
      "block"
    );
    setTimeout(() =>
      this.renderer.setStyle(this.el.nativeElement.children[1], "opacity", "1")
    );
    this.renderer.setStyle(
      this.el.nativeElement.children[1],
      "transition",
      "opacity .3s"
    );
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.renderer.removeClass(this.el.nativeElement, "open");
    setTimeout(
      () =>
        this.renderer.setStyle(
          this.el.nativeElement.children[1],
          "display",
          "none"
        ),
      200
    );
    this.renderer.setStyle(this.el.nativeElement.children[1], "opacity", "0");
    this.renderer.setStyle(
      this.el.nativeElement.children[1],
      "transition",
      "opacity .3s"
    );
  }
}
